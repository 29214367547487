import React from "react";
import "./Featured.css";

function Featured() {
  return (
    <section id="featured">
      <div className="row featured__row">
        <div className="featured__wrapper">
          <span className="feature__text">
            CONTACT ME \ 0418158541 \ Blisslifesolutions@outlook.com \ KEEP UP TO DATE F: @Blisslifesolutions
          </span>
        </div>
      </div>
    </section>
  );
}

export default Featured;
