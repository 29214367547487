import { useEffect, useState } from 'react';
import AboutBLS from '../../Components/AboutBLS/AboutBLS';
import AboutMe from '../../Components/AboutMe/AboutMe';
import Header from '../../Components/Header/Header';
import Navbar from '../../Components/Navbar/Navbar';
import Services from '../../Components/Services/Services';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Featured from '../../Components/Featured/Featured';
import Testimonials from '../../Components/Testimonials/Testimonials';
import Enquire from '../../Components/Enquire/Enquire';
import "./Home.css"
import FAQ from '../../Components/FAQ/FAQ';
import Footer from '../../Components/Footer/Footer';
import firebaseApp from '../../firebaseConfig';

function Home() {
  const [cardData, setCardData] = useState([]);
  
  useEffect(() => {
    async function getCardData() {
      try {
        const db = getFirestore(firebaseApp);
        const querySnapshot = await getDocs(collection(db, "Services"));
        const data = querySnapshot.docs.map(doc => doc.data());
        setCardData(data);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    }

    getCardData();
    
  }, []);

  useEffect(() => {
    
  }, [cardData]);

  return (
    <div className="home">
     
      <Header />
      {/* <Featured/> */}
      <AboutBLS />
      <Testimonials/>
      <Services cardData={cardData} />
      <FAQ />
      <Enquire />
      <Footer main={true}/>
    </div>
  );
}

export default Home;
