import React, { useState, useEffect } from "react";
import "./Testimonials.css";
import { useWindowSize } from "@react-hook/window-size";

const testimonials = [
  {
    quote:
      "I like to check in with Elisha every six months for a session or two to get back on track, stay motivated, and give myself a life reset. Her guidance keeps me accountable and helps me move forward and make progress.",
    author: "Lucy L",
  },
  {
    quote:
      "Working with Bliss Life Solutions has been a game-changer for me. Elisha's guidance and expertise have helped me navigate through life's challenges with ease. With Elisha's support, I feel more motivated and in control of my life. Her straightforward and honest approach has made a significant impact on my personal growth and well-being. I highly recommend Bliss Life Solutions to anyone seeking to improve their life and achieve their goals.",
    author: "Darrren C",
  },
  {
    quote:
      "Elisha has been a total lifesaver with my finances. She helps me figure out a budget, keep track of my spending, and plan for what's coming up. Thanks to her, I feel way more in control of my money and confident about my choices. Her advice is super practical, and she's always there to check in with. I honestly don’t know where I'd be without her help.",
    author: "Mark M",
  },
  {
    quote:
      "Bliss Life Solutions has been a game-changer for our family. Thanks to Elisha's help, our home life is so much better now. There's way less fighting and a lot more harmony. Her advice on communication, planning, and dealing with conflicts made a huge difference. With her support, we’ve learned to tackle challenges together and create a peaceful, loving environment. We can't thank Elisha enough for helping us get back on track.",
    author: "Rebbeca W",
  },
];

function Testimonials() {
  const [width, height] = useWindowSize();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const { quote, author } = testimonials[currentTestimonial];

  return (
    <section id="testimonials">
      <div className="row testimonials__row">
        <div className="testimonial__wrapper">
          <div className="testimonial__text">
            <span className="quotemarks">"</span>
            <p className="testimonial__quote">{quote}</p>
            <span className="testimonial__author">{author}</span>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
