import React from "react";
import "./FAQ.css";
import Question from "../Question/Question";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLink, faPhone } from "@fortawesome/free-solid-svg-icons";

function FAQ() {
  return (
    <section id="faq">
      <div className="row faq__row">
        <h3 className="services__subtitle">FREQUENTLY ASKED QUESTIONS</h3>
        <div className="faq__container">
          <Question
            title={"What is life coaching? And how does it work?"}
            para={
              <>
                Life coaching is about empowering you to create a fulfilling life. As your coach, I’ll help you understand your values, strengths, and direction.<br/><br/>
                Expect powerful conversations where we assess your current situation, clarify your desires, and set inspiring goals. My style of life coaching is personalised and holistic, addressing all aspects of your life to ensure a balanced approach to your well-being. This differs from other life coaches because I focus not just on long-term dreams but also on achievable milestones to guide your journey step by step.
              </>
            }
          />
          <Question
            title={"Is a life coach the same as a counsellor?"}
            para={
              <>
               Life coaches focus on accountability and future-oriented guidance, helping clients achieve their goals. Counsellors, on the other hand, address past issues, treat mental illness, addictions, and past trauma. While life coaching will touch on the past to gain more knowledge for the future, our main priority is the present and future.
              </>
            }
          />
        
          <Question
            title={"What should I expect during our sessions?"}
            para={
              <>
                              Imagine having a cup of tea with a friend who’s there for you, but also has an outside perspective to offer. That’s what our sessions will feel like.<br/><br/>
               I’m here to support you, provide guidance, and help you see things from a new angle. Together, we’ll work on your goals and make sure you’re moving forward in the right direction.
              </>
            }
          />
          <Question
            title={"What sets me apart from other life coaches?"}
            para={
              <>
                I don't believe in sugarcoating things—I'm here to challenge you in the best way possible. My coaching style is honest and straightforward, helping you make real progress and take charge of your actions.<br/><br/>
                Think of me as a supportive friend who isn't afraid to tell it like it is. I keep our conversations real and authentic, encouraging you to embrace your true self too.
              </>
            }
          />
          <Question
            title={"How long does coaching typically last?"}
            para={
              <>
               Firstly we will start off with a single 60-minute session. For some clients, this initial session suffices, while others opt for a more extended coaching journey.
<br/><br/>
I value flexibility, understanding that everyone’s timeline will differ based on their unique needs, goals, and pace of growth. For those seeking a deeper dive, I offer extended sessions that last 2 hours or more. These allow for comprehensive exploration, profound insights, and robust support. Typically, most clients find that a coaching program spanning 3 to 12 months suits their needs best.

              </>
            }
          />
        </div>
      </div>
    </section>
  );
}

export default FAQ;
