import React from "react";
import "./AboutBLS.css";
import AboutblsImage from "../Images/AboutblsImage.svg";
export default function AboutBLS() {
  return (
    <section id="about-bls">
      <div className="row about-bls__row">
        <div className="about-bls__container">
          <div className="about-bls__text">
            <h2 className="about-bls__title">
              Welcome to
              <span className="bliss-blue"> Bliss Life Solutions</span>
            </h2>

            <p className="about-bls__para">
            Hi I'm Elisha, a certified life coach with a passion for helping others. Life can be tough, and it's natural to feel lost or overwhelmed at times. I'm here to support you through those moments.
            <br/> <br/>
I am an everyday person who has learned from real-life experiences. I’ve worked in various fields, from childcare to accounting, and I’ve owned a cleaning, organising, and decluttering business. I’ve also worked in civil construction and power stations, thriving in male-dominated industries. I’ve been the spouse working away and the spouse at home raising the children. <br/> <br/>

I’ve experienced marriage, divorce, and have since found new love with a man who has three children of his own, forming our version of a blended family.
I am well-travelled and have raised two amazing children, who are now living their own blissful lives. Through all these experiences, I’ve learned valuable lessons that have shaped who I am today.
<br/> <br/>
I believe in continuous growth and learning, so let’s progress together. My coaching focuses on your development, offering straightforward and honest guidance. I help you reach your goals swiftly by encouraging accountability. No sugarcoating—just genuine, empowering support.
            </p>
          </div>
          <img src={AboutblsImage} alt="" className="about-bls__image" />
        </div>
      </div>
    </section>
  );
}
