import React, { useContext, useEffect, useState } from "react";
import "./Enquire.css";
import Logo from "../Images/Logo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faEnvelope,
  faMessage,
  faPaperPlane,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";
import { quickEnquireContext } from "../../App";

function Enquire() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    subject: "",
    email: "",
    message: "",
  });
  const { quickEnquireData } = useContext(quickEnquireContext);
  const [success, setSuccess] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  useEffect(() => {
    if (quickEnquireData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subject: quickEnquireData,
      }));
    }
    console.log(quickEnquireData);
  }, [quickEnquireData]);

  const sendEmail = (e) => {
    e.preventDefault();
    setSuccess(true);
    setFormSubmitted(false)
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
    try {
      emailjs
        .send("service_gn0jxnd", "template_xj6j9h2", formData, {
          cc: formData.email,
          formData,
          publicKey: "n0Zs7Cfm-sdtRjm9g",
        })
        .then(
          () => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              firstName: "",
              subject: "",
              email: "",
              message: "",
            }));
            window.alert("An email has been sent successfully to Bliss Life Solutions");
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }


  function isFormValid() {
    return (
      formData.firstName.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.subject.trim() !== "" &&
      formData.message.trim() !== ""
    );
  }
  return (
    <section id="enquire">
      <div className="row enquire__row">
        <div className="form__container">
          <div className="form__side__bar">
            <div className="form__title">
 <h3 className="form__title">Let's Work Together.</h3>
          <span className="form__subtitle">
          Get in touch today and begin your blissful life.
          </span>
          
            </div>

            <span className="form__subtitle">
            Ph:0418158541
          </span>
          <span className="form__subtitle">
            Email:Blisslifesolutions@outlook.com
          </span>
          <span className="form__subtitle">
            FB:@Blisslifesolutions
          </span>
                  <img src={Logo} alt="" className="enquire__image"/>
        
          </div>
         
          <form onSubmit={sendEmail} className="form__input__wrapper">
            <div className="form__names">
              <div className="input__name__wrapper">
                <input
                  className="input "
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  value={formData.firstName}
                  placeholder=" "
                  required
                />
                <label
                  className={`input__wrapper__label ${
                    formSubmitted && formData.firstName.trim() === ""
                      ? "invalid"
                      : ""
                  }`}
                >
                  Name
                </label>
                <FontAwesomeIcon
                  icon={faUser}
                  className="input__wrapper__icon"
                />
              </div>
              
            </div>

            <div className="input__email__wrapper">
              <input
                className="input"
                type="email"
                onChange={handleChange}
                name="email"
                value={formData.email}
                placeholder=" "
                required
              />
              <label
                  className={`input__wrapper__label ${
                    formSubmitted && formData.email.trim() === ""
                      ? "invalid"
                      : ""
                  }`}
                >
                  Email
                </label>
              <FontAwesomeIcon
                icon={faEnvelope}
                className="input__wrapper__icon"
              />
            </div>

            <div className="input__subject__wrapper">
              <input
                className="input"
                type="text"
                onChange={handleChange}
                name="subject"
                value={formData.subject}
                placeholder=" "
                required
              />
              <label
                  className={`input__wrapper__label ${
                    formSubmitted && formData.subject.trim() === ""
                      ? "invalid"
                      : ""
                  }`}
                >
                  Subject
                </label>
              <FontAwesomeIcon
                icon={faClipboard}
                className="input__wrapper__icon"
              />
            </div>

            <div className="input__message__wrapper">
              <textarea
                className="input message__input"
                type="text"
                onChange={handleChange}
                name="message"
                value={formData.message}
                placeholder=" "
                required
              />
              <label
                  className={`input__wrapper__label ${
                    formSubmitted && formData.message.trim() === ""
                      ? "invalid"
                      : ""
                  }`}
                >
                  Message
                </label>
              <FontAwesomeIcon
                icon={faMessage}
                className="input__wrapper__icon"
              />
            </div>

            <div className="form__buttons">
              <button
                className="from__button"
                disabled={success}
                onClick={(e) => {
                  e.preventDefault();
                  setFormSubmitted(true);
                  if (!isFormValid()) {
                    setInvalidForm(true);
                  } else {
                    sendEmail(e);
                  }
                }}
                
              >
                Send Message
                
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Enquire;
