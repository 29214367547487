import React from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import WhiteLogo from "../Images/WhiteLogo.svg";
import { useViewport } from "react-viewport-hooks";

import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import NavHamburger from "../NavHamburger/NavHamburger";
import { HashLink } from "react-router-hash-link";
import Logo from "../Images/Logo.svg";

import { Link } from "react-scroll";
function Navbar({ main }) {
  const { vw, vh } = useViewport();

  return (
    <nav id="navbar">
      <div className=" row nav__row ">
        
        <div className="nav__info__container">
          
          {vw > 1000 ? (
            <div className="logo__container">
        
        <img
            className="nav__logo"
            href="#"
            src={Logo}
            alt="Bliss Logo"
          />
        <div className="logo__container__text">
          <span className="logo__text">
            Bliss Life Solutions
          </span>
          <span className="logo__text subtext">
          Life Reset, Life Organisation, Life Management
          </span>
        </div>
            </div>
          
          
          
                
        ) : (
          <></>
        )}
        </div>
         <div className="nav__links">
            
             
                <Link
                  className="nav__link"
                  smooth
                  to="about-bls"
                  duration={500}
                >
                  ABOUT
                </Link>

                <Link className="nav__link" smooth to="services" duration={500}>
                  SERVICES
                </Link>

                {vw > 1000 ? (
                  <></>      
        ) : (
          <img
            className="nav__logo"
            href="#"
            src={Logo}
            alt="Bliss Logo"
          />
        )}
                <Link
                  className="nav__link"
                  smooth
                  to="testimonials"
                  duration={500}
                >
                  TESTIMONIALS
                </Link>

                <Link className="nav__link" smooth to="faq" duration={500}>
                  FAQ
                </Link>

                
       
          </div>
        
        
      </div>
      
    </nav>
  );
}

export default Navbar;
