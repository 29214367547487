import {React, useContext} from "react";
import ServiceBG from "../Images/serviceBG.png"
import { quickEnquireContext } from "../../App";
import { useNavigate, } from "react-router-dom";
import './Services.css';
import Card from "../Card/Card";

function Services({cardData}) {
  const {quickEnquireData,setQuickEnquireData} = useContext(quickEnquireContext)
  const navigate = useNavigate();


  const handleCardClick = (title) => {
    setQuickEnquireData(title)
    console.log(quickEnquireContext)
  }

  const handleLearnMoreClick = (card) => {
    console.log(card)
    navigate(`/Service/${card.id}`, { state: { card } });
    window.scrollTo(0, 0);
  };
  console.log(cardData)
  return (
    <section id="services">
    
            {cardData.map((card, index) =>
                <Card
                key={card.id}
                icon={card.icon}
                title={card.title}
                subtitle={card.subtitle}
                cardDes={card.cardDes}
                Para={card.para}
                img={card.img}
                orientation={index % 2 === 0 ? 0 : 1}
                OnQuickEnquire={() => handleCardClick(card.title)}
                onLearnMore={() => handleLearnMoreClick(card)}
              />
            )}
   

    </section>
  );
}

export default Services;
