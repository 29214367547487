import React from "react";
import "./Card.css";

import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { useViewport } from "react-viewport-hooks";

const parseHtmlString = (htmlString) => {
  return { __html: htmlString.replace(/<br\s*\/?>/g, "<br>") };
};

function Card({ title, cardDes, orientation, Para, img, OnQuickEnquire, onLearnMore, icon }) {
  const selectedIcon = Icons[icon];
  const { vw } = useViewport();

  return (
    <section id="card__sections" className={orientation === 1 ? "card__backgrounded" : ""}>
      <div className="row cards__row">
      <div className="card">
        {vw > 860 && orientation === 0 ? (
          <img src={img} alt={title} className="card__image" />
        ) : vw > 860 && orientation !== 2 ? (
          <></>
        ) : (
          <img src={img} alt={title} className="card__image" />
        )}
        <div className="card__container">
          <h5 className="card__title">{title}</h5>
          <p className="card__para" dangerouslySetInnerHTML={parseHtmlString(Para)}></p>
          <Link
            to="enquire"
            smooth={true}
            duration={500}
            className="card__button"
            onClick={OnQuickEnquire}
          >
            Quick Enquire
          </Link>
        </div>
        {vw > 860 && orientation === 1 ? (
          <img src={img} alt={title} className="card__image" />
        ) : (
          <></>
        )}
      </div>
      </div>
    </section>
  );
}

export default Card;
